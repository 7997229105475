import { isNud } from '@/utilities/typing'

/**
 * ファイルマスタ エンティティ
 */
class File {
    file_id;
    file_type;
    file_path;
    thumbnail_path;
    original_file_name;
    file_size;
    mime_type;
    file_url;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.file_id = properties.file_id;
        this.file_type = properties.file_type;
        this.file_path = properties.file_path;
        this.thumbnail_path = properties.thumbnail_path;
        this.original_file_name = properties.original_file_name;
        this.file_size = properties.file_size;
        this.mime_type = properties.mime_type;
        this.file_url = properties.file_url;
    }
}

export default File;

import Enum from '../enum'

/**
 * 応援カメラマン 名刺の有無
 */
class HasBusinessCard extends Enum {
    static YES = 1; // 有
    static NO = 0; // 無

    static values() {
        return {
            [this.YES]: '有',
            [this.NO]: '無',
        }
    }
}

export default HasBusinessCard;
import { isNud } from '@/utilities/typing'
import Photographer from '@/models/entities/photographer';
import Skill from '@/models/entities/skill';

/**
 * カメラマンスキルトラン エンティティ
 */
class PhotographerSkill {
    photographer_skill_id;
    photographer;
    skill;
    skill_level;
    note;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.photographer_skill_id = properties.photographer_skill_id;
        this.photographer = new Photographer(properties.photographer);
        this.skill = new Skill(properties.skill);
        this.skill_level = properties.skill_level;
        this.note = properties.note;
    }
}

export default PhotographerSkill;
import { isNud } from '@/utilities/typing'
import BannedPhotographer from '@/models/entities/banned-photographer';
import PhotographerSkill from '@/models/entities/photographer-skill';
import File from '@/models/entities/file';
import Role from '@/models/enums/employee/role';
import IsPhotographer from '@/models/enums/employee/is-photographer';
import HasBusinessCard from '@/models/enums/partner/has-business-card';
import HasPledge from '@/models/enums/partner/has-pledge';

/**
 * カメラマン エンティティ
 */
class Photographer {
    photographer_id;
    photographer_type;
    photographer_name;
    shortened_name;
    is_photographer;
    login_key;
    roles;
    partner_rank;
    has_business_card;
    has_pledge;
    zip;
    pref;
    address;
    tel;
    email;
    birth_year;
    has_car;
    car_type;
    details;
    pleasant_status;
    entrance_ceremony_status;
    sports_day_status;
    special_equipment;
    weak_equipment;
    personal_note;
    rank_up_problem;
    file;
    skills;
    banned_photographers;


    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.photographer_id = properties.photographer_id;
        this.photographer_type = properties.photographer_type;
        this.photographer_name = properties.photographer_name;
        this.shortened_name = properties.shortened_name;
        this.is_photographer = properties.is_photographer ?? IsPhotographer.NOT_PHOTOGRAPHER;
        this.login_key = properties.login_key;
        this.roles = [];
        if ('roles' in properties) {
            this.roles = properties.roles.map(val => parseInt(val, 10));
        }
        this.partner_rank = properties.partner_rank;
        this.has_business_card = properties.has_business_card ?? HasBusinessCard.NO;
        this.has_pledge = properties.has_pledge ?? HasPledge.NO;
        this.zip = properties.zip;
        this.pref = properties.pref;
        this.address = properties.address;
        this.tel = properties.tel;
        this.email = properties.email;
        this.birth_year = properties.birth_year;
        this.has_car = properties.has_car;
        this.car_type = properties.car_type;
        this.details = properties.details;
        this.pleasant_status = properties.pleasant_status;
        this.entrance_ceremony_status = properties.entrance_ceremony_status;
        this.sports_day_status = properties.sports_day_status;
        this.special_equipment = properties.special_equipment;
        this.weak_equipment = properties.weak_equipment;
        this.personal_note = properties.personal_note;
        this.rank_up_problem = properties.rank_up_problem;
        this.file = new File(properties.file);
        this.skills = [];
        if ('skills' in properties) {
            for (let skill of properties.skills) {
                this.skills.push(new PhotographerSkill(skill));
            }
        }
        this.banned_photographers = [];
        if ('banned_photographers' in properties) {
            for (let banned_photographer of properties.banned_photographers) {
                this.banned_photographers.push(new BannedPhotographer(banned_photographer));
            }
        }
    }

    get roles_display() {
        return Role.join(this.roles);
    }
}

export default Photographer;

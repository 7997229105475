<template>
    <div :class="{[optional_class]: true}">
        <label class="form-check-inline form-control">
            <input
                type="checkbox"
                class="form-check-input"
                v-model="checked_option"
                @change="$emit('update:modelValue', this.checked_option)"
                :true-value="option_key_true"
                :false-value="option_key_false"
                :disabled="disabled"
            > <span class="form-check-label" :class="{[optional_label_class]: true}">{{ option_label }}</span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'FormCheckbox',
    props: {
        modelValue: {
        },
        option_key_true: { //ON の際のvalue値
            type: Number,
            default: 1
        },
        option_key_false: { //OFF の際のvalue値
            type: Number,
            default: 0
        },
        option_label: {
            type: String,
            default: ''
        },
        optional_class: {
            type: String,
            default: ''
        },
        optional_label_class: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            checked_option: this.modelValue,
        }
    },
    watch: {
        modelValue() {
            this.checked_option = this.modelValue;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

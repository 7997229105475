import Enum from '../enum'

/**
 * 応援カメラマン 評価
 */
class Evaluation extends Enum {
    static GOOD = 1; // ○
    static NORMAL = 2; // △
    static BAD = 3; // ×

    static values() {
        return {
            [this.GOOD]: '○',
            [this.NORMAL]: '△',
            [this.BAD]: '×',
        }
    }
}

export default Evaluation;
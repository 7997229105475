<template>
    <div class="form-upload-wrap">
        <template v-if="file.file_id">
            <div class="bg-light">
                <img :src="viewpoint()" :alt="file.original_file_name" class="form-upload-preview-image">
                <div class="form-upload-preview-filename">
                    {{ file.original_file_name }}
                </div>
            </div>
        </template>

        <inline-loader v-if="uploading"></inline-loader>
        <template v-else-if="$helper.isNud(file.file_id)">
            <label class="btn btn-outline-secondary">
                <input type="file" @change="upload" style="display:none;">
                <i class="bi bi-person-bounding-box"></i> {{ label }}
            </label>
        </template>
        <template v-else>
            <button type="button" @click="remove" class="form-uploader-remove"><i class="bi bi-trash"></i></button>
        </template>
    </div>
</template>

<script>
import File from '@/models/entities/file';
import FileType from '@/models/enums/file-type';
import InlineLoader from '@/components/tools/InlineLoader';

export default {
    name: 'FormUpload',
    components: {
        InlineLoader
    },
    data() {
        return {
            //アップロード中
            uploading: false,
            //ファイルオブジェクト (通常はmodelValueのコピー)
            file: () => {return new File();},
        }
    },
    props: {
        modelValue: {},
        file_type: {
            type: Number,
            default: null
        },
        label: {
            type: String,
            default: 'アップロード'
        },
    },
    emits: [
        'update:modelValue'
    ],
    mounted() {
        this.setModel();
    },
    watch: {
        modelValue: function () {
            this.setModel();
        },
    },
    methods: {
        //外から値が変わったとき
        setModel() {
            if (this.$helper.isNud(this.modelValue)) {
                this.file = new File();
                this.file.file_type = this.file_type;
            } else if (this.modelValue instanceof File) {
                // if (this.modelValue.file_type !== this.file_type) {
                //     console.log(this.modelValue);
                //     console.log(this.file_type);
                //     throw 'FormUpload コンポーネントの file_type と、model の file_type が一致しません';
                // }
                this.file = this.modelValue;
            } else {
                throw 'FormUpload コンポーネントの value は、Fileクラスのオブジェクトである必要があります';
            }
        },
        //ファイルアップロード→成功したら emit
        upload(event) {
            let formData = new FormData()
            let uploading_file = event.target.files[0];
            formData.append('file', uploading_file);

            this.uploading = true;
            let headers = {
                'content-type': 'multipart/form-data'
            };
            this.$http.post(this.endpoint(), formData, {headers})
            .then(response => {
                let uploaded_file = new File(response.data);
                this.$emit('update:modelValue', uploaded_file);
            })
            .finally(() => {
                this.uploading = false;
            });
        },
        //ファイルを外す
        remove() {
            this.$emit('update:modelValue', null);
        },
        //アップロード先URL
        endpoint() {
            if (this.file_type === FileType.SCHEDULE) {
                return `/schedules/${this.schedule_id}/files`;
            }

            if (this.file_type === FileType.PARTNER) {
                return `/partners/files`;
            }

            throw 'file_typeがセットされていません';
        },
        //閲覧先URL
        viewpoint() {
            if (this.file_type === FileType.SCHEDULE) {
                return `/schedules/${this.schedule_id}/files/${this.file.file_id}?access_token=${this.$store.state.auth.access_token}`;
            }

            if (this.file_type === FileType.PARTNER) {
                return `${process.env.VUE_APP_API_URI}/partners/files/${this.file.file_id}?access_token=${this.$store.state.auth.access_token}`;
            }
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-upload-wrap {position: relative; width: 100%; max-width: 400px; padding: 1rem;}
.form-upload-preview-image {width: 100%; height: auto;}
.form-uploader-remove {position: absolute; right: 0.5rem; top: 0.5rem; border: none; background: #FFF; border-radius: 50%;}

</style>

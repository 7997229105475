import Enum from './enum'

/**
 * ファイル 種類
 */
class FileType extends Enum {
    static SCHEDULE = 1; // スケジュール
    static PARTNER = 2; // 応援カメラマン

    static values() {
        return {
            [this.SCHEDULE]: 'スケジュール',
            [this.PARTNER]: '応援カメラマン',
        }
    }
}

export default FileType;
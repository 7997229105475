import Enum from '../enum'

/**
 * 応援カメラマン ランク
 */
class PartnerRank extends Enum {
    static PREMIUM = 1; // プレミアム
    static SEMI_REGULAR = 2; // 準レギュラー
    static TRAINING = 3; // 育成
    static POPULAR_SPOT = 4; // 人気スポット
    static SPOT = 5; // スポット
    static OUT = 6; // 戦力外

    static values() {
        return {
            [this.PREMIUM]: 'プレミアム',
            [this.SEMI_REGULAR]: '準レギュラー',
            [this.TRAINING]: '育成',
            [this.POPULAR_SPOT]: '人気スポット',
            [this.SPOT]: 'スポット',
            [this.OUT]: '戦力外',
        }
    }
}

export default PartnerRank;
import { isNud } from '@/utilities/typing'

/**
 * スキルマスタ エンティティ
 */
class Skill {
    skill_id;
    skill_name;
    priority;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.skill_id = properties.skill_id;
        this.skill_name = properties.skill_name;
        this.priority = properties.priority;
    }
}

export default Skill;

import { isNud } from '@/utilities/typing'
import Photographer from '@/models/entities/photographer';
import School from '@/models/entities/school';

/**
 * 出禁カメラマントラン エンティティ
 */
class BannedPhotographer {
    banned_photographer_id;
    photographer;
    school;


    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.banned_photographer_id = properties.banned_photographer_id;
        this.photographer = new Photographer(properties.photographer);
        this.school = new School(properties.school);
    }
}

export default BannedPhotographer;
